import React, { ReactElement, useState, useCallback, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@/components/controls/Button'
import { GetApp } from '@material-ui/icons'
import DocImage from '@/assets/word1.png'
import PdfImage from '@/assets/pdf1.png'
import ExcelImage from '@/assets/excel.png'
import Loader from '@/components/blocks/Loader'

import { TicketsApi } from '@/api/sd/ticketsApi'
import { useClasses } from './styles'
import { Props } from './types'
import { exportFromByteArray } from '@/utils/fileActions'

const RenderFiles = ({ fileInfo, ticketId }: Props): ReactElement => {
  const [file, setFile] = useState<Blob>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const classes = useClasses()

  const getSourceByName = useCallback((name: string) => {
    const [ext] = name.match(/\.[^.]+$/) || ['']
    if (ext === '.pdf') {
      return PdfImage
    }
    if (ext === '.xls' || ext === '.xlsx') {
      return ExcelImage
    }
    return DocImage
  }, [])

  const getFile = useCallback(async (fileId: string, ticketId: string) => {
    const response = await TicketsApi.getTicketDetailsFile(`${fileId}`, `${ticketId}`)

    setFile(response)
  }, [])

  const downloadFile = useCallback(() => exportFromByteArray(file, fileInfo.name), [file, fileInfo])

  useEffect(() => {
    if (fileInfo && ticketId) {
      getFile(`${fileInfo.id || fileInfo.fileId}`, ticketId)
    }
  }, [fileInfo, ticketId, getFile])

  useEffect(() => {
    if (file) setIsLoading(false)
  }, [file])

  return (
    <Box display="flex" flexWrap="wrap">
      <div className={classes.file}>
        <img
          className={classes.extension}
          alt="file extension"
          src={getSourceByName(fileInfo.name)}
        />
        <div className={classes.fileName}>
          <Box
            fontWeight="fontWeightBold"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {fileInfo.name}
          </Box>
          <div>{file && `${parseFloat(`${file.size / 1000}`)} KB`}</div>
        </div>
        <Button
          width="xs"
          variant="contained"
          className={classes.fileSave}
          onClick={downloadFile}
          disabled={isLoading}
        >
          {isLoading ? <Loader inline /> : <GetApp fontSize="small" />}
        </Button>
      </div>
    </Box>
  )
}

export default RenderFiles
